<template>
  <v-card class="mt-sm-8 mt-md-5 mt-7 bg-transparent">
    <div class="card-padding pb-0">
      <v-img src="@/assets/badgepacker.png" class="" height="auto"> </v-img>
      <h4 class="text-h2 mt-10 font-weight-bolder text-typo mb-2">Einloggen</h4>
      <p class="mb-0 text-typo">
        Geben Sie Ihre Business E-Mail-Adresse und Ihr Passwort ein, um sich zu
        einzuloggen.
      </p>
    </div>

    

    <div class="card-padding pb-4">
      
      <label class="text-xs text-typo font-weight-bolder ms-1"
        >Business E-Mail</label
      >
      <v-text-field
        v-model="form.email"
        :rules="emailRules"
        hide-details
        outlined
        background-color="rgba(255,255,255,.9)"
        color="rgba(0,0,0,.6)"
        light
        height="40"
        placeholder="Business E-Mail"
        class="input-style font-size-input border border-radius-md placeholder-lighter text-color-light mb-4 mt-2"
      >
      </v-text-field>

      <label class="text-xs text-typo font-weight-bolder ms-1">Passwort</label>
      <v-text-field
        v-model="form.password"
        :rules="min8"
        hide-details
        outlined
        background-color="rgba(255,255,255,.9)"
        color="rgba(0,0,0,.6)"
        light
        height="40"
        type="password"
        placeholder="Passwort"
        class="input-style font-size-input border border-radius-md placeholder-lighter text-color-light mb-4 mt-2"
      >
      </v-text-field>
      <p class="text-danger">
        {{ authMessage.message }}
      </p>
      <v-btn
        elevation="0"
        :ripple="false"
        height="43"
        class="font-weight-bold text-uppercase btn-primary bg-gradient-primary py-2 px-6 me-2 mb-2 w-100"
        color="#5e72e4"
        small
        @click="submitForm"
        ><span v-if="!updating">Einloggen</span>
        <v-progress-circular
          v-else
          indeterminate
          color="white"
        ></v-progress-circular></v-btn
      >
      <div class="text-center">
        <p class="text-sm text-body mt-3 mb-0">
          Sie haben noch keinen Account?
          <a
            @click="$router.push('/signup').catch(() => {})"
            class="text-primary text-gradient text-decoration-none font-weight-bold"
            >Registrieren</a
          >
        </p>
      </div>
    </div>
  </v-card>
</template>
<script>
export default {
  name: "login",
  data() {
    return {
      checkbox: false,
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => v.length <= 10 || "Name must be less than 10 characters",
      ],
      emailRules: [
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "Bitte gebe eine gültige E-Mail-Adresse an",
      ],
      rules: [
        (value) => !!value || "Erforderlich.",
        (value) => (value && value.length >= 3) || "Min 3 Zeichen",
      ],
      min8: [(value) => (value && value.length >= 7) || "Min 8 Zeichen"],
      min6: [
        (value) =>
          (value && value.length > 5 && value.length < 7) || "6 Zeichen",
      ],
      loginDialog: false,
      registerDialog: false,
      ratingDialog: false,
      suggestionDialog: false,
      dialog: false,
      form: {
        verificationCode: "",
      },
    };
  },
  computed:{
    updating(){
      return this.$store.getters.updating;
    },
    authMessage() {
      return this.$store.getters.authMessage;
    },
  },
  methods: {
    submitForm() {
      let payload = {
        email: this.form.email,
        password: this.form.password,
      };
      this.$store.dispatch("loginModal", payload);
    },
  },
};
</script>
